<template>
    <div class="view-page department-maintenance">
        <div class="page-header">
            <h1>
                部署マスタ
                <button
                    class="addButton"
                    @click="showEditor()"
                    v-if="hasTicket('dept-c')"
                >
                    ＋
                </button>
            </h1>
            <div>
                <div>
                    <OpCheckbox
                        label="無効データも表示"
                        v-model="visibleInvalid"
                        name="visible_invalid"
                    />
                </div>
            </div>
        </div>

        <transition name="strans" mode="out-in">
            <div style="position:relative; z-index:0;" v-if="!executing">
                <div class="alegend" v-if="visibleInvalid">
                    ★ ：有効 ／ ☆ ：無効（有効開始前） ／ - ：無効（期限切れ）
                </div>
                <table style="margin:0 auto;">
                    <tbody
                        @click="showView(data)"
                        v-for="(data, ix) in dlist"
                        :key="ix"
                    >
                        <tr :class="getExdivClass(data)">
                            <th class="rownum" rowspan="1">
                                {{ ix + 1 }}
                            </th>
                            <td rowspan="1" v-if="visibleInvalid">
                                {{ getExdivMark(data) }}
                            </td>
                            <td rowspan="1">
                                <div
                                    class="value"
                                    :class="
                                        data.department_div == 0
                                            ? 'divname0'
                                            : 'divname1'
                                    "
                                >
                                    {{
                                        getDepartmentDivName(
                                            data.department_div
                                        )
                                    }}
                                </div>
                            </td>
                            <td>
                                <div class="title">
                                    部署ID
                                </div>
                                <div class="value">
                                    {{ data.department_id }}
                                </div>
                            </td>
                            <td>
                                <div class="title">
                                    有効期間
                                </div>
                                <div class="value">
                                    {{ data.start_date | date }} <wbr /> -
                                    {{ data.end_date | date }}
                                </div>
                            </td>
                            <td>
                                <div class="title">
                                    部署CD
                                </div>
                                <div class="value">
                                    {{ data.department_code }}
                                </div>
                            </td>
                            <td>
                                <div class="title">
                                    部署名
                                </div>
                                <div class="value">
                                    {{ data.department_name_long }}
                                </div>
                            </td>
                            <td>
                                <div class="title">
                                    部署略称
                                </div>
                                <div class="value">
                                    {{ data.department_name_short }}
                                </div>
                            </td>
                            <td>
                                <div
                                    class="title"
                                    v-if="data.department_div == 1"
                                >
                                    所属
                                </div>
                                <div
                                    class="value"
                                    v-if="data.department_div == 1"
                                >
                                    <span class="id">{{
                                        data.upper_department_id
                                    }}</span>
                                    <span class="code">{{
                                        data.upper_department_code
                                    }}</span>
                                    {{ data.upper_department_name_short }}
                                </div>
                            </td>
                        </tr>
                        <!-- <tr>
                        <td colspan="3">
                            <div class="title">
                                有効期間
                            </div>
                            <div class="value">
                                {{ data.start_date | date }} <wbr /> -
                                {{ data.end_date | date }}
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="title">備考</div>
                            <div class="value">
                                {{ data.department_note }}
                            </div>
                        </td>
                    </tr> -->
                    </tbody>
                </table>
            </div>
        </transition>
        <OpModal
            :title="viewDialogTitle"
            v-if="showViewDialog"
            :alterButton="hasTicket('dept-u')"
            :changeButton="hasTicket('dept-u')"
            :copyButton="hasTicket('dept-c')"
            :removeButton="hasTicket('dept-d')"
            v-slot="{ close }"
            @close="showViewDialog = false"
            @clickAlter="showAlterEditor"
            @clickChange="showChangeEditor"
            @clickRemove="showDeleteConfirm = true"
            @clickCopy="showCopyEditor"
        >
            <DepartmentView
                name="deparmtmentView"
                v-model="selectedData"
                @fin="close"
                @loadedData="loadedSelectedData"
            />
        </OpModal>
        <OpModal
            :title="editDialogTitle"
            v-if="showEditDialog"
            @close="showEditDialog = false"
            v-slot="{ close, step }"
            :steps="['入力', '確認', '完了']"
        >
            <DepartmentEditor
                v-model="selectedData"
                name="departmentEditor"
                :mode="editMode"
                @fin="close"
                @step="step"
                @updated-data="updatedData"
                @created-data="createdData"
                @moveto="showView"
            />
        </OpModal>

        <OpModal
            v-if="showDeleteConfirm"
            title="部署の削除"
            @close="showDeleteConfirm = false"
            v-slot="{ close, step }"
            :steps="['確認', '完了']"
        >
            <OpConfirm
                confirm="削除してもよろしいですか？"
                complete="削除しました。"
                :okfunction="deleteDept"
                @fin="close"
                @step="step"
            ></OpConfirm>
        </OpModal>
    </div>
</template>

<script>
import DepartmentView from "@/views/department/DepartmentView.vue";
import DepartmentEditor from "@/views/department/DepartmentEditor.vue";
import OpConfirm from "@/components/OpConfirm.vue";
import OpCheckbox from "@/components/OpCheckbox.vue";
import DateUtils from "@/DateUtils.js";

// const axios = require("axios");

export default {
    name: "DepartmentList",
    components: {
        DepartmentView,
        DepartmentEditor,
        OpCheckbox,
        OpConfirm
    },
    data: function() {
        return {
            showViewDialog: false,
            viewDialogTitle: "",
            selectedData: null,
            showEditDialog: false,
            editDialogTitle: "",
            editMode: "",
            dlist: [],
            showDeleteConfirm: false,
            visibleInvalid: false,
            executing: false
        };
    },
    watch: {
        visibleInvalid: function() {
            this.search();
        }
    },
    methods: {
        showEditor: function(data) {
            this.editMode = "create";
            this.selectedData = data;
            this.editDialogTitle = "部署登録 ( ID : 自動採番 )";
            this.showEditDialog = true;
        },
        showAlterEditor: function() {
            let data = this.selectedData;
            this.editMode = "update";
            this.editDialogTitle =
                "部署情報の修正  ( ID : " + data.department_id + " )";
            this.showEditDialog = true;
        },
        showChangeEditor: function() {
            let data = this.selectedData;
            this.editMode = "change";
            this.editDialogTitle =
                "部署情報の変更  ( ID : " + data.department_id + " )";
            this.showEditDialog = true;
        },
        showCopyEditor: function() {
            this.showEditor(this.selectedData);
        },
        showView: function(data) {
            this.selectedData = data;
            this.viewDialogTitle = "部署  ( ID : " + data.department_id + " )";
            this.showViewDialog = true;
        },
        search: function() {
            this.executing = true;
            let params = {};
            if (!this.visibleInvalid) {
                params["rdate"] = DateUtils.toRequestDate(
                    DateUtils.getNowDate()
                );
            }
            return this.readDataToArray(this.makeurl("dept", params), list => {
                let pobj = {};
                for (let dept of list) {
                    if (dept.department_div == 0) {
                        let d = pobj[dept.department_id];
                        if (d) {
                            if (!d.others) {
                                d.others = [];
                            }
                            d.others.push(dept);
                        } else {
                            pobj[dept.department_id] = dept;
                        }
                    }
                }
                for (let dept of list) {
                    if (dept.department_div == 1) {
                        let p = pobj[dept.upper_department_id];
                        if (p) {
                            // dept.upper_department_name =
                            //     p.department_name_short;
                            // dept.upper_department_code = p.department_code;
                            if (!p.children) {
                                p.children = {};
                            }
                            let c = p.children[dept.department_id];
                            if (c) {
                                if (!c.others) {
                                    c.others = [];
                                }
                                c.others.push(dept);
                            } else {
                                p.children[dept.department_id] = dept;
                            }
                            // p.children.push(dept);
                        }
                    }
                }

                let dlist = [];
                for (const pdid in pobj) {
                    const pdept = pobj[pdid];
                    dlist.push(pdept);
                    if (pdept.others) {
                        for (const podept of pdept.others) {
                            dlist.push(podept);
                        }
                    }
                    const cren = pdept.children;
                    for (const cdid in cren) {
                        const cdept = cren[cdid];
                        dlist.push(cdept);
                        if (cdept.others) {
                            for (const codept of cdept.others) {
                                dlist.push(codept);
                            }
                        }
                    }
                }
                this.dlist = dlist;
            }).finally(() => (this.executing = false));
        },
        loadedSelectedData: function(data) {
            this.selectedData = data;
        },
        updatedData: function(value) {
            this.selectedData = value;
            this.search();
        },
        createdData: function() {
            this.search();
        },
        deleteDept: function(func) {
            let d = this.selectedData;
            let data = this.convertToRequestData(d);
            let url = this.makeurl("dept", data.department_id, data.start_date);
            this.deleteData(
                url,
                data,
                () => {
                    func();
                    this.showViewDialog = false;
                    this.selectedData = null;
                    this.search();
                },
                () => {
                    this.showDeleteConfirm = false;
                }
            );
        },
        getExdivMark: function(data) {
            let ret = "★";
            let c = this.compareExdate(data);
            if (c > 0) {
                ret = "☆";
            } else if (c < 0) {
                ret = "-";
            }
            return ret;
        },
        getExdivClass: function(data) {
            let ret = "";
            let c = this.compareExdate(data);
            if (c > 0) {
                ret = "future";
            } else if (c < 0) {
                ret = "past";
            }
            return ret;
        },
        compareExdate: function(data) {
            let ret = 0;
            let now = DateUtils.getNowDate();
            if (data.end_date && data.end_date < now) {
                ret = -1;
            } else if (data.start_date && data.start_date > now) {
                ret = 1;
            }
            return ret;
        },
        getDepartmentDivName: function(code) {
            let ret = "";
            let div = this.divs["dept"][code];
            if (div == null) {
                ret = code;
            } else {
                ret = div.contentlong;
            }
            return ret;
        }
    },
    created: async function() {
        await this.loaddivs("dept");
        await this.search();
    }
};
</script>

<style scoped>
/* .add-button {
    width: 30px;
    height: 30px;
    padding: 5px;
} */

thead {
    display: none;
}
td {
    vertical-align: top;
    border-right-style: none;
    border-left-style: none;
}
td .title {
    font-size: 0.5em;
    display: block;
}
td .value {
    padding: 3px 0;
}
td .value .id {
    font-size: 0.75em;
    display: inline-block;
}
td .value .id:after {
    content: " : ";
}
td .item {
    display: inline-block;
    vertical-align: top;
    padding: 2px 10px;
}
tbody {
    cursor: pointer;
}
tbody:nth-child(2n + 1) {
    background: var(--odd-bg-color);
}
tbody tr td {
    border-style: none;
}
tbody {
    border-top: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
}

.future {
    opacity: 0.5;
}
.past {
    opacity: 0.5;
}
.divname1 {
    text-align: right;
    font-size: 0.5rem;
}

.alegend {
    margin: 15px auto;
    text-align: center;
    font-size: 0.75rem;
    color: var(--color-gray);
}

.strans-enter-active,
.strans-leave-active {
    /* animation: sizeScale 0.5s ease reverse; */
    transition: 0.25s;
}

.strans-enter,
.strans-leave-to {
    /* animation: sizeScale ease 0.5s; */
    opacity: 0;
}
</style>
