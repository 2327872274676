<template>
    <div class="dept-editor">
        <div class="form" v-if="isStep(0)">
            <div class="dept-form">
                <!-- <div class="content dept-id">
                    <div class="title">部署ID</div>
                    <span class="value">
                        <span v-if="isCreate()">
                            登録時に採番されます
                        </span>
                        <span v-else>
                            {{ d.department_id }}
                        </span>
                    </span>
                </div> -->
                <div class="item dept-div">
                    <OpDivSelector
                        label="区分"
                        v-model="d.department_div"
                        :divlist="divs['dept']"
                        name="department_div"
                        required
                        :readonly="!isCreate()"
                        @changed-valid="changedValid"
                    />
                </div>
                <div class="item dept-upper">
                    <OpSelectDepartment
                        label="所属事業所"
                        v-model="d.upper_department"
                        name="upper-department"
                        type="short"
                        div="0"
                        :required="d.department_div == 1"
                        :disabled="d.department_div == 0"
                        @changed-valid="changedValid"
                    />
                </div>
                <div class="item dept-code">
                    <OpTextField
                        label="部署CD"
                        v-model.trim="d.department_code"
                        maxlength="3"
                        required
                        name="department_code"
                        ref="department_code"
                        pattern="A9"
                        @changed-valid="changedValid"
                    />
                </div>
                <div class="item valid-date">
                    <OpDateRangeTextField
                        label="有効期間"
                        v-model.trim="d.dates"
                        name="dates"
                        :startReadOnly="isUpdate()"
                        :endReadOnly="notEditableEndDate()"
                        required="start"
                        :mindate="minstartdate()"
                        @changed-valid="changedValid"
                    />
                </div>

                <div class="item dept-name-short">
                    <OpTextField
                        label="部署名（短縮）"
                        v-model.trim="d.department_name_short"
                        maxlength="20"
                        required
                        name="department_name_short"
                        @changed-valid="changedValid"
                    />
                </div>
                <div class="item dept-name-long">
                    <OpTextField
                        label="部署名（正式）"
                        v-model.trim="d.department_name_long"
                        maxlength="80"
                        required
                        name="department_name_long"
                        @changed-valid="changedValid"
                    />
                </div>
            </div>
            <div class="control-box">
                <button @click="doCancel">キャンセル</button>
                <button @click="setStep(1)" v-bind:disabled="hasErrors">
                    確認
                </button>
            </div>
        </div>
        <div v-if="isStep(1)">
            <div class="content">
                <table class="confirm">
                    <tr>
                        <th>部署ID</th>
                        <td>
                            <span class="value">
                                <span v-if="isCreate()">
                                    登録時に採番されます
                                </span>
                                <span v-else>
                                    {{ d.department_id }}
                                </span>
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <th>区分</th>
                        <td>
                            <span class="value">{{
                                getDepartmentDivName(d.department_div)
                            }}</span>
                        </td>
                    </tr>
                    <tr v-if="d.department_div == 1">
                        <th>所属部署</th>
                        <td>
                            <span class="value">{{
                                d.upper_department.department_name_short
                            }}</span>
                        </td>
                    </tr>
                    <tr>
                        <th>部署CD</th>
                        <td>
                            <span class="value">{{ d.department_code }}</span>
                        </td>
                    </tr>
                    <tr>
                        <th>有効期間</th>
                        <td>
                            <span class="value">
                                {{ d.dates.start | date }} -
                                {{ d.dates.end | date }}</span
                            >
                        </td>
                    </tr>
                    <tr>
                        <th>部署名（正式）</th>
                        <td>
                            <span class="value">{{
                                d.department_name_long
                            }}</span>
                        </td>
                    </tr>
                    <tr>
                        <th>部署名（短縮）</th>
                        <td>
                            <span class="value">{{
                                d.department_name_short
                            }}</span>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="control-box">
                <button class="control-button" @click="setStep(0)">
                    戻る
                </button>
                <button
                    class="control-button"
                    v-if="isUpdate() || isChange()"
                    @click="updateData"
                    :disabled="requesting"
                >
                    登録
                </button>
                <button
                    class="control-button"
                    v-else
                    @click="addData"
                    :disabled="requesting"
                >
                    登録
                </button>
            </div>
        </div>
        <div v-if="isStep(2)" class="complete">
            <div class="message">
                部署 (ID : {{ d.department_id }}, 開始日 :
                {{ d.start_date | date }})
                <span v-if="mode == 'update'">の情報を修正しました。</span>
                <span v-else>を登録しました</span>
            </div>

            <div class="control-box">
                <div></div>
                <div>
                    <button @click="close">
                        閉じる
                    </button>
                </div>
                <div>
                    <button v-if="mode != 'update'" @click="moveDetail">
                        詳細
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EditorMixin from "@/views/EditorMixin.vue";
import OpDateRangeTextField from "@/components/OpDateRangeTextField.vue";
import OpSelectDepartment from "@/components/OpSelectDepartment.vue";
import OpDivSelector from "@/components/OpDivSelector.vue";
import DateUtils from "@/DateUtils";

const axios = require("axios");

export default {
    name: "DepartmentEditor",
    mixins: [EditorMixin],
    model: {
        prop: "model"
    },
    components: {
        OpDateRangeTextField,
        OpSelectDepartment,
        OpDivSelector
    },
    props: {
        model: {
            default: function() {
                return {};
            },
            type: Object
        },
        mode: String
    },
    data: function() {
        return {
            requesting: false,
            d: this.initData(this.model),
            maxd: null
        };
    },
    methods: {
        initData: function(model, mode) {
            let ret = { ...model };
            if (mode == "change") {
                ret.start_date = null;
                ret.end_date = null;
            }
            if (ret.end_date && ret.end_date.getFullYear() > 9000) {
                ret.end_date = null;
            }
            ret.dates = {
                start: ret.start_date,
                end: ret.end_date
            };
            ret.upper_department = {
                department_id: ret.upper_department_id,
                department_code: ret.upper_department_code,
                department_name_short: ret.upper_department_name_short,
                department_name_long: ret.upper_department_name_long
            };

            return ret;
        },
        isUpdate: function() {
            return this.mode == "update";
        },
        isCreate: function() {
            return this.mode == "create";
        },
        isChange: function() {
            return this.mode == "change";
        },
        makeUpdateData: function() {
            this.d.start_date = this.d.dates.start;
            this.d.end_date = this.d.dates.end;
            if (this.d.upper_department) {
                this.d.upper_department_id = this.d.upper_department.department_id;
            } else {
                this.d.upper_department_id = null;
            }

            let data = this.convertToRequestData(this.d);
            if (data.end_date == null) {
                data.end_date = "99991231";
            }
            return data;
        },
        addData: function() {
            let url = this.makeurl("dept");

            let data = this.makeUpdateData();

            this.requesting = true;
            this.postData(url, data, d => {
                this.d = this.initData(d);
                this.setStep(2);
                this.$emit("created-data", this.d);
            }).then(() => {
                this.requesting = false;
            });
        },
        updateData: function() {
            let data = this.makeUpdateData();

            let url = this.makeurl("dept", data.department_id, data.start_date);
            this.requesting = true;
            this.putData(url, data, d => {
                this.d = this.initData(d);
                this.setStep(2);
                if (this.isUpdate()) {
                    this.$emit("updated-data", this.d);
                } else {
                    let url1 = this.makeurl(
                        "dept",
                        this.model.department_id,
                        this.model.start_date
                    );
                    this.readData(url1, od => {
                        this.$emit("updated-data", od);
                    });
                }
            }).then(() => {
                this.requesting = false;
            });
        },

        readdepartment: async function(id, strdate, obj) {
            axios.get(this.makeurl("dept", id, strdate)).then(response => {
                obj.d = this.convertFromResponseData(response.data);
            });
        },

        minstartdate: function() {
            let ret = null;
            if (this.isChange() && this.maxd) {
                if (this.maxd.end_date) {
                    ret = this.maxd.end_date;
                } else {
                    ret = this.maxd.start_date;
                }
                ret = new Date(ret.getTime());
                ret.setDate(ret.getDate() + 1);
            }
            return ret;
        },
        notEditableEndDate: function() {
            let ret = false;
            if (this.isUpdate() && this.maxd) {
                if (this.maxd.start_date) {
                    ret =
                        DateUtils.formatDate(this.d.start_date) !=
                        DateUtils.formatDate(this.maxd.start_date);
                }
            }
            return ret;
        },
        moveDetail: function() {
            this.$emit("moveto", this.d);
            this.close();
        },
        getDepartmentDivName: function(code) {
            let ret = "";
            let div = this.divs["dept"][code];
            if (div == null) {
                ret = code;
            } else {
                ret = div.contentlong;
            }
            return ret;
        }
    },
    // watch: {
    //     currentStep: function(value) {
    //         this.$emit("step", value);
    //     }
    // },
    created: async function() {
        this.loaddivs("dept");
        if (this.isChange() || this.isUpdate()) {
            await this.readData(
                this.makeurl("dept", this.d.department_id, "x"),
                d => {
                    this.maxd = d;
                    if (this.isChange() && d) {
                        if (d.end_date) {
                            let ed = d.end_date;
                            ed = new Date(ed.getTime());
                            ed.setDate(ed.getDate() + 1);
                            this.d.dates = { start: ed, end: null };
                        }
                    }
                }
            );
        }
    },
    mounted: function() {
        this.$refs.department_code.focus();
    }
};
</script>

<style scoped>
.dept-editor {
    min-width: 640px;
}
.control-box {
    display: grid;
    border-top: 1px solid var(--border-color);
    padding: 10px 0;
    grid-template-columns: 200px 1fr 200px;
    justify-items: center;
}

.dept-form {
    position: relative;
    display: grid;
    grid-template-areas:
        "dept-div         dept-upper        dept-upper       emp0            emp"
        "valid-date       valid-date        dept-code        dept-code       emp"
        "dept-name-long   dept-name-long    dept-name-long   dept-name-long  dept-name-long"
        "dept-name-short  dept-name-short   dept-name-short  emp1            emp1";

    grid-row-gap: 10px;
    grid-column-gap: 20px;
}

.dept-form .dept-div {
    grid-area: dept-div;
}
.dept-form .dept-upper {
    grid-area: dept-upper;
}
.dept-form .dept-attr .item {
    display: flex;
}

.dept-form .dept-id {
    grid-area: dept-id;
}
.dept-form .dept-code {
    grid-area: dept-code;
}
.dept-form .valid-date {
    grid-area: valid-date;
}
.dept-form .dept-name-short {
    grid-area: dept-name-short;
}
.dept-form .dept-name-long {
    grid-area: dept-name-long;
}
.dept-form .dept-name-long > * {
    width: 100%;
}
.dept-form .dept-index {
    grid-area: dept-index;
}
.dept-form .dept-index > * {
    width: 100%;
}

.dept-form .salesorder-flag {
    grid-area: salesorder-flag;
}
.dept-form .purchaseorder-flag {
    grid-area: purchaseorder-flag;
}
.dept-form .user-flag {
    grid-area: user-flag;
}
.dept-form .deposit-cutoff-day {
    grid-area: deposit-cutoff-day;
}
.dept-form .deposit-term {
    grid-area: deposit-term;
}
.dept-form .deposit-day {
    grid-area: deposit-day;
}
.dept-form .deposit-flag {
    grid-area: deposit-flag;
    align-self: center;
}
.dept-form .deposit-bank {
    grid-area: deposit-bank;
}
.dept-form .payment-cutoff-day {
    grid-area: payment-cutoff-day;
}
.dept-form .payment-term {
    grid-area: payment-term;
}
.dept-form .payment-day {
    grid-area: payment-day;
}
.dept-form .payment-flag {
    grid-area: payment-flag;
    align-self: center;
}
.dept-form .dept-note {
    grid-area: dept-note;
}
.dept-form .dept-note > * {
    width: 100%;
}
</style>
