<template>
    <div class="view-page">
        <div class="content  dept-panel">
            <!-- <div class="item dept-id">
                <div class="title">取引先ID</div>
                <span class="value">
                    {{ d.department_id }}
                </span>
            </div> -->
            <div class="item dept-div">
                {{ getDepartmentDivName(d.department_div) }}
            </div>
            <div class="item dept-upper" v-if="d.department_div == 1">
                <div class="title">所属事業所</div>
                <span class="value">
                    <span class="id">{{ d.upper_department_id }}</span>
                    <span class="code">{{ d.upper_department_code }}</span>
                    {{ d.upper_department_name_short }}
                </span>
            </div>
            <div class="item dept-code">
                <div class="title">部署CD</div>
                <span class="value">{{ d.department_code }}</span>
            </div>
            <div class="item valid-date">
                <div class="title">有効期間</div>
                <span class="value">
                    {{ d.start_date | date }} - {{ d.end_date | date }}</span
                >
            </div>
            <div class="item dept-name-long">
                <div class="title">部署名（正式）</div>
                <span class="value">{{ d.department_name_long }}</span>
            </div>
            <div class="item dept-name-short">
                <div class="title">部署名（略称）</div>
                <span class="value">{{ d.department_name_short }}</span>
            </div>

            <!-- <div class="item " v-if="d.department_div == 0">
                <div class="title">所属しているグループ</div>
                <div class="value" v-for="(c, ix) in d.children" :key="ix">
                    <span class="id">{{ c.department_id }}</span>
                    <span class="code">{{ c.department_code }}</span>
                    {{ c.department_name_short }}
                </div>
            </div> -->
            <!-- <div class="item dept-note">
                <div class="title">備考</div>
                <span class="value">{{ d.department_note }}</span>
            </div> -->
        </div>

        <div class="sysinfo">
            <div class="item">
                <div class="title">作成</div>
                <span class="value">{{ d.create_timestamp | timestamp }}</span>
                <span class="value">{{ d.create_account_id }}</span>
            </div>
            <div class="item" v-if="d.update_timestamp">
                <div class="title">変更</div>
                <span class="value">{{ d.update_timestamp | timestamp }}</span>
                <span class="value">{{ d.update_account_id }}</span>
            </div>
            <div class="item version">
                <div class="title">version</div>
                <span class="value">{{ d.row_version }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "DepartmentView",

    model: {
        prop: "model"
    },
    props: {
        model: Object
    },
    watch: {
        model: function(val) {
            this.d = val;
        }
    },
    data: function() {
        return {
            d: this.model
        };
    },
    methods: {
        getDepartmentDivName: function(code) {
            let ret = "";
            let div = this.divs["dept"][code];
            if (div == null) {
                ret = code;
            } else {
                ret = div.contentlong;
            }
            return ret;
        }
    },
    created: async function() {
        this.loaddivs("dept");
    }
};
</script>

<style scoped>
.view-page {
    min-width: 520px;
}
.dept-panel {
    position: relative;
    display: grid;
    grid-template-areas:
        "dept-div        dept-upper     emp          "
        "valid-date       valid-date     dept-code           "
        "dept-name-long   dept-name-long   dept-name-short  ";

    grid-row-gap: 10px;
    grid-column-gap: 30px;
}
.dept-panel .dept-div {
    grid-area: dept-div;
}
.dept-panel .dept-upper {
    grid-area: dept-upper;
}

.dept-panel .dept-id {
    grid-area: dept-id;
}
.dept-panel .dept-code {
    grid-area: dept-code;
}
.dept-panel .valid-date {
    grid-area: valid-date;
}
.dept-panel .dept-name-short {
    grid-area: dept-name-short;
}
.dept-panel .dept-name-long {
    grid-area: dept-name-long;
}
.dept-panel .dept-name-long > * {
    width: 100%;
}

/* --- */
.noselected {
    text-decoration: line-through;
}
.selected::before {
    content: "✔ ";
}

/* --- */
.sysinfo {
    font-size: 0.75rem;
    text-align: right;
}
.sysinfo .item {
    display: inline-block;
    padding: 1rem;
}
.sysinfo .item .title {
    display: inline-block;
    vertical-align: top;
}
.sysinfo .item .title:after {
    content: " : ";
}
.sysinfo .item .value {
    display: inline-block;
    padding-left: 0.5rem;
}

.sysinfo .version {
    display: none;
}
</style>
